<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.rewardType"
            clearable
            placeholder="奖励类型"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in rewardTypeOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>

    <el-select
            v-model="query.isvalid"
            clearable
            placeholder="状态"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
          v-for="item in isValidOptions"
          :key="item.key"
          :label="item.display_name"
          :value="item.key"
      />
    </el-select>
    <el-select
        v-model="query.ibLvl"
        clearable
        placeholder="级别"
        class="filter-item"
        style="width: 130px"
        @change="toQuery"
    >
      <el-option
          v-for="(item, index) in levelList"
          :key="index"
          :label="item.levelName"
          :value="item.id"
      />
    </el-select>
     <el-select
             v-model="query.mtGroup"
             clearable
             placeholder="分组"
             class="filter-item"
             style="width: 130px"
             @change="toQuery"
     >
       <el-option
               v-for="item in mtGroups"
               :key="item.id"
               :label="item.groupName"
               :value="item.id"
       > </el-option>
    </el-select>
     <el-select
             v-model="query.tradeCat"
             clearable
             placeholder="种类"
             class="filter-item"
             style="width: 130px"
             @change="toQuery"
     >
      <el-option v-for="item in categorys" :key="item.id" :label="item.catName" :value="item.id"> </el-option>
    </el-select>
    <el-input
            v-model="query.ruleName"
            clearable
            placeholder="规则名称"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="toQuery"
    />
    <el-button
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="toQuery"
    >搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','COMMISSIONRULES_ALL','COMMISSIONRULES_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="addHandle">新增</el-button>

    </div>
    <el-button
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-close"
            @click="clear"
    >清空</el-button>
  </div>
</template>

<script>
import { querySystemLevel } from '@/api/ib/tManager'
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
import { mapGetters } from 'vuex'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      levelList: [],
    }
  },
  computed: {
    ...mapGetters([
      'rewardTypeOptions',
      'isValidOptions',
      'mtGroups',
      'categorys'
    ]),
  },
  created(){
    this.getLevelList();
  },
  methods: {
    checkPermission,
    ibLevChange(ibLvl){
      this.query.ibLvl = ibLvl;
    },
    toQuery() {
      this.$emit('toQuery')
    },
    ibLvlChangeHandle(ibLvl){
      this.$emit('ibLvl-change',ibLvl);
    },
    clear(){
      this.$emit('clear');
    },
    getLevelList(){
      querySystemLevel().then((res) => {
        this.levelList = res;
      })
    },
    addHandle(){
      this.$emit('addHandldle');
    }
  }
}
</script>
