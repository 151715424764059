<template>
  <el-dialog
    :append-to-body="true"
    :visible="dialog"
    :title="isAdd ? '新增' : '编辑'"
     width="800px"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-position="top"
    >
      <el-row class="row">
        <el-col class="col">
          <el-form-item label="佣金规则名称" prop="ruleName">
            <el-input class="span1" v-model="form.ruleName" style="width: 600px;"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col class="col" :span="8">
          <el-form-item prop="isvalid" label="状态">
            <el-select class="select" v-model="form.isvalid" placeholder="选择状态" style="width: 150px;">
              <el-option
                v-for="item in isValidOptions"
                :key="item.key + 'valid'"
                :label="item.display_name"
                :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col class="col" :span="8">
          <el-form-item prop="rewardType" label="奖励类型">
            <el-select class="select" v-model="form.rewardType" placeholder="奖励类型" style="width: 150px;" @change="changeRewardType">
              <el-option
                v-for="item in rewardTypeOptions"
                :key="item.key"
                :label="item.display_name"
                :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col class="col" :span="8">
          <el-form-item v-if="levelFlag" prop="ibLvl" label="适用级别">
            <el-select  class="select" v-model="form.ibLvl" placeholder="选择级别" style="width: 150px;">
              <el-option
                v-for="item in ibLevelOptions"
                :key="item.id"
                :label="item.levelName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
     <el-row class="row" >
        <el-col class="col" v-if="isNotDirect">
          <el-form-item prop="commType" label="返佣模式">
            <el-select  class="select" v-model="form.commType" placeholder="返佣模式" style="width: 700px;">
              <el-option
                v-for="item in commTypeOptions"
                :key="item.key"
                :label="item.display_name"
                :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col class="col">
          <el-form-item label="交易种类">
<!--            如选了Stock则其他种类置灰不可选,反之选了其他种类,Stock置灰 todo-->
<!--            很难实现,涉及到多选和单选,多选的初始值是[],单选的初始值是 ''-->
            <el-select
                ref="elselect"
                class="select"
                multiple
                v-model="tradeCats"
                placeholder="交易种类"
                style="width: 700px;"
                @change="categoryChange"
            >
              <el-option
                v-for="item in categorys"
                :key="parseString(item.id)"
                :label="item.catName"
                :value="item.catName"
                :disabled="getDisabled(item)"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col class="col">
          <el-form-item label="MT分组">
            <el-select  class="select" multiple  v-model="mtGroupss" placeholder="MT分组" style="width: 700px;">
              <el-option
                v-for="item in mtGroups"
                :key="parseString(item.id)"
                :label="item.groupName"
                :value="item.groupName">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row">
        <el-col class="col" :span="12">
          <el-form-item prop="directCommType" label="直客返佣类型">
            <el-select
                class="select"
                v-model="form.directCommType"
                placeholder="选择返佣类型"
                style="width: 250px;"
            >
              <el-option
                v-for="item in directCommTypeComputeOptions"
                :key="item.key"
                :label="item.display_name"
                :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col class="col" :span="12">
<!--          返佣类型选择%时,输入框后面显示%,支持两位小数,
              选择其他则不显示%
-->
          <el-form-item
              prop="directCommValue"
              label="直客返佣数值"
          >
            <el-input
                class="select"
                v-model="form.directCommValue"
                style="width: 250px;"
            >
              <span
                  v-if="form.directCommType == 4"
                  slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="row" v-if="isNotDirect">
        <el-col class="col" :span="12">
          <el-form-item prop="indirectCommType" label="间接客户返佣类型">
            <el-select class="select" v-model="form.indirectCommType" placeholder="选择返佣标准类型" style="width: 250px;">
              <el-option
                v-for="item in indirectCommTypeOptions"
                :key="item.key"
                :label="item.display_name"
                :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--<el-col class="col" :span="12">
          <span class="span">{{"间接客户返佣数值"}}</span>
           <el-input class="select" v-model="form.indirectCommValue" style="width: 250px;"/>
        </el-col>-->
      </el-row>
      <!--<el-row class="row" v-if="isNotDirect">
        <el-col class="col" :span="12">
         <span class="span">{{"平级奖励百分比%"}}</span>
         <el-input class="select" v-model="form.peerRewardValue" style="width: 250px;"/>
        </el-col>
        <el-col class="col" :span="12">
         <span class="span">{{"越级奖励百分比%"}}</span>
         <el-input class="select" v-model="form.superiorRewardValue" style="width: 250px;"/>
        </el-col>
      </el-row>-->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button
          :loading="loading"
          type="primary"
          @click="doSubmit"
      >确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { maxOneFloatOrNone, maxTwoFloatOrNone } from '@/utils/validate'
import { add, edit } from '@/api/bussetup/tMerchantCommissionRules'
import { mapGetters } from 'vuex'
import { queryMerchantCommissionLevel, querySystemLevel } from '@/api/ib/tManager.js';
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      default(){
        return {

        }
      }
    }
  },

  created(){
    if(this.mtGroups==null ){
       this.$store.dispatch('GetMtGroups');
    }
    if(this.categorys==null){
      this.$store.dispatch('GetCategorys');
    }
  },
  data() {
    let _this = this;
    var valueValidateDirectComm = (rule, value, callback) => {
      if(_this.form.directCommType !== 4){
        if (value === '') {
          callback(new Error('请填写直客返佣数值'));
        } else {
          callback();
        }
      }else {
        //验证为支持一位小数,
        if(value === ''){
          callback(new Error('请填写直客返佣数值'));
        }else {
          if (!maxOneFloatOrNone(value)) {
            callback(new Error('最多支持一位小数'));
          } else {
            callback();
          }
        }
      }
    };
    return {
      ibLevelOptions:[],
      loading: false,
      isNotDirect:true,
      tradeCats:[],
      mtGroupss:[],
      rules:{
        ruleName:[
          {required: true, message: '请填写佣金规则名称',trigger: 'blur'}
        ],
        isvalid:[
          {required: true, message: '请选择状态',trigger: 'change'}
        ],
        rewardType:[
          {required: true, message: '请选择奖励类型',trigger: 'change'}
        ],
        ibLvl:[
          {required: true, message: '请选择适用级别',trigger: 'change'}
        ],
        commType:[
          {required: true, message: '请选择返佣模式',trigger: 'change'}
        ],
        directCommType:[
          {required: true, message: '请选择直客返佣类型',trigger: 'change'}
        ],
        directCommValue:[
          // {required: true, message: '请填写直客返佣数值',trigger: 'blur'},
          {validator: valueValidateDirectComm,trigger: 'blur'}
        ],
        indirectCommType:[
          {required: true, message: '请选择间接客户返佣类型',trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        ruleName: '',
        rewardType: 1,
        commType: 1,
        ibLvl: '',
        tradeCat: '',
        mtGroup: '',
        directCommType: '',
        directCommValue: '',
        indirectCommType: '',
        // indirectCommValue: '',
        peerRewardValue: '',
        superiorRewardValue: '',
        isvalid: 1
      },
      levelFlag: true,

      stockOptions: [
        //  代联调 todo
        {display_name: '%',key: 4,}
      ]
    }
  },
  watch:{
    rowData: {
      handler(newValue){
        if(newValue.id && !this.isAdd){
          this.form = {
            id: newValue.id,
            ruleName: newValue.ruleName,
            rewardType: newValue.rewardType,
            commType: newValue.commType,
            ibLvl: newValue.ibLvl,
            tradeCat: newValue.catName,
            mtGroup: newValue.groupName,
            directCommType: newValue.directCommType,

            directCommValue: newValue.directCommValue,
            indirectCommType: newValue.indirectCommType,
            indirectCommValue: newValue.indirectCommValue,
            peerRewardValue: newValue.peerRewardValue,
            superiorRewardValue: newValue.superiorRewardValue,
            isvalid: newValue.isvalid
          }
          if(newValue.directCommType == 4){
            this.form.directCommValue = newValue.directCommValue * 100;
          }

          this.tradeCats = newValue.catName.split(',')
          this.mtGroupss = newValue.groupName.split(',')
          if(newValue.rewardType == 2){
            this.isNotDirect = false
          }
        }
      },
      deep: true,
    },
    form:{
      handler(newVal){
        this.setFlag();
      },
      deep: true,
      immediate: true
    },
    dialog(newVal){

      if(newVal) {
        this.getLevels();
        this.changeRewardType();
      }
    },
  },
  computed: {
    ...mapGetters([
      'rewardTypeOptions',
      'commTypeOptions',
      'directCommTypeOptions',
      'indirectCommTypeOptions',
      'isValidOptions',
      'mtGroups',
      'categorys'
    ]),

    directCommTypeComputeOptions(){
      if(!this.tradeCats.includes('Stock')){
        return this.directCommTypeOptions;
      }else {
        return this.stockOptions;
      }
    }
  },
  methods: {
    getDisabled(item){
      // 选中

      if(this.tradeCats.length){
        if(this.tradeCats.includes('Stock')){
          if(item.catName !== 'Stock'){
            return true;
          }else {
            return false;
          }
        }else if(this.tradeCats.length && !this.tradeCats.includes('Stock')){
          if(item.catName !== 'Stock'){
            return false;
          }else {
            return true;
          }
        }
      }else {
        //没有选中
        return false;
      }
    },
    categoryChange(value){
      console.log(value)
      this.$refs.elselect.$forceUpdate();
      if(value.length && this.tradeCats.includes('Stock')){
        this.form.directCommType = 4;
      }else {
        if(this.form.directCommType = 4){
          this.form.directCommType = '';
        }
      }
    },
    handleClose(){
      this.$emit('close');
    },
    setFlag(){
      if(this.form.rewardType == 2){
        this.levelFlag = false;
      }else if(this.form.rewardType == 1){
        this.levelFlag = true;
      }
    },
    getLevels(){
      querySystemLevel().then((res) => {
        this.ibLevelOptions = res;
      })
    },
    parseString(data){
      return data.toString();
    },
    changeRewardType(){
      if(this.form.rewardType==2){
        this.isNotDirect=false;
      } else{
        this.isNotDirect=true;
      }
    },
    cancel() {
      this.$emit('close');
      // this.resetForm();
    },
    doSubmit() {
      this.$refs.form.validate((valia) => {
        if(valia){
          if(!this.tradeCats.length){
            this.$message.error('请选择交易种类');
            return;
          };
          if(!this.mtGroupss.length){
            this.$message.error('请选择MT分组');
            return;
          }
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        }else {
          console.log('验证不通过')
        }

      })
    },
    parseCat() {
      var categotyStr=''
      for(var i=0;this.tradeCats.length>i;i++){
        categotyStr+=this.tradeCats[i]
        if(i<this.tradeCats.length-1){
          categotyStr+=','
        }
      }
      return categotyStr
    },
    parseMT() {
      var MtStr=''
      for(var i=0;this.mtGroupss.length>i;i++){
        MtStr+=this.mtGroupss[i]
        if(i<this.mtGroupss.length-1){
          MtStr+=','
        }
      }
      return MtStr
    },
    doAdd() {

      let params = this.handlerParams();
      add(params).then(res => {

        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        });
        this.loading = false;
        this.resetForm();
        this.$emit('success');
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    handlerParams(){
      if(this.form.rewardType==2){
        this.form.commType='';
        this.form.indirectCommType= '';
        // this.form.indirectCommValue= '';
        this.form.peerRewardValue= '';
        this.form.superiorRewardValue= '';
        this.form.ibLvl = '';
      }
      this.form.tradeCat = this.parseCat();
      this.form.mtGroup = this.parseMT();
      let params = {
        ...this.form,
      }
      if(this.tradeCats.includes('Stock')){
        params.directCommValue = params.directCommValue / 100;
      }
      return params;
    },
    doEdit() {

      let params = this.handlerParams();
      edit(params).then(res => {

        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$emit('success');
        this.resetForm();
      }).catch(err => {
        this.loading = false;
        console.log(err.response.data.message);
      })
    },
    resetForm() {
      this.dialog = false;
      this.mtGroupss=[];
      this.tradeCats=[];
      this.$refs['form'].resetFields();
      this.form = {
        id: '',
        ruleName: '',
        rewardType: 1,
        commType: 1,
        ibLvl: '',
        tradeCat: '',
        mtGroup: '',
        directCommType: '',
        directCommValue: '',
        indirectCommType: '',
        indirectCommValue: '',
        peerRewardValue: '',
        superiorRewardValue: '',
        isvalid: 1
      }
    }
  }
}
</script>

<style scoped>
.row{
    margin-right: -15px;
    margin-left: -15px;
    display: block;
}
.col{
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
    display: block;
    min-width: 20%
}
.span{
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  cursor: default;
  display: block;
}

.span1{
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  cursor: default;
}
.select{
    background-color: #FFF;
    background-image: none;
    color: inherit;
    /* padding: 6px 12px; */
    font-size: 14px;
    height: 34px;
    margin-bottom: 15px;
    display: block;
}
</style>
