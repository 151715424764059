<template>
  <div class="app-container">
    <eHeader
      @ibLvl-change="ibLevChange"
      :query="query"
      @toQuery="toQuery"
      @clear="clearHandle"
      @addHandldle="addHandldle"
    />
    <!--表格渲染-->
    <el-table
        v-loading="loading"
        :data="data"
        size="small"
        stripe
        style="width: 100%;"
    >
      <el-table-column prop="id" label="NO." min-width="50%"/>
      <el-table-column prop="ruleName" label="规则名称"/>
      <el-table-column prop="rewardType" label="奖励类型">
         <template slot-scope="scope">
          <span>{{ parseRewardType(scope.row.rewardType) }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="commType" label="返佣模式">
        <template slot-scope="scope">
          <span>{{ scope.row.commType === 1 ? '内佣' : '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="levelName" label="适用级别"/>
      <el-table-column prop="tradeCat" label="交易种类">
        <template slot-scope="scope">
          <span>{{ getTradeCat(scope.row.catName) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="mtGroup" label="MT分组">
        <template slot-scope="scope">
          <span>{{ getMtGroup(scope.row.groupName) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="返佣规则" min-width="150%">
        <template slot-scope="scope" >
          <pre >{{ parseRules(scope.row) }}</pre>
        </template>
      </el-table-column>
      <el-table-column prop="isvalid" label="状态">
        <template slot-scope="scope">
          <span>{{ parseIsValid(scope.row.isvalid) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          min-width="280px"
          align="center"
      >
        <template slot-scope="scope">
          <el-button
              size="mini"
              class="my-button"
              type="success"
              style="margin-right: 3px;"
              @click="editHandle(scope.row)"
          >编辑</el-button>
          <el-dropdown
            size="mini"
            v-if="getSomeOne(scope.row) && !scope.row.custId && checkPermission(['ADMIN','COMMISSIONRULES_ALL'])"
          >
            <el-button size="mini" type="success">
              更多操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                class="dropdown-item"
                v-if="!scope.row.custId && checkPermission(['ADMIN','COMMISSIONRULES_ALL','COMMISSIONRULES_DISABLE'])"
              >
                <el-popover
                  :ref="scope.row.ruleName"
                  placement="top"
                  width="180">
                  <p>确定{{scope.row.isvalid==1?'禁用':'启用'}}本条数据吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="$refs[scope.row.ruleName].doClose()">取消</el-button>
                    <el-button :loading="modeifyLoading" type="primary" size="mini" @click="subStatus(scope.row)">确定</el-button>
                  </div>
                  <el-button class="my-button" slot="reference" type="danger" size="mini">{{scope.row.isvalid==1?'禁用':'启用'}}</el-button>
                </el-popover>
              </el-dropdown-item>
              <el-dropdown-item
                class="dropdown-item"
                v-if="!scope.row.custId && checkPermission(['ADMIN','COMMISSIONRULES_ALL','COMMISSIONRULES_DELETE'])"
              >
                <el-popover
                  :ref="scope.row.id"
                  placement="top"
                  width="180">
                  <p>确定删除本条数据吗？</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                    <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
                  </div>
                  <el-button class="my-button" slot="reference" type="danger" size="mini">删除</el-button>
                </el-popover>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <e-form-dialog
        ref="formdialog"
        :is-add="isAdd"
        :row-data="currentRow"
        :dialog="dialogShow"
        @success="success"
        @close="dialogCloseHandle"
    ></e-form-dialog>
  </div>
</template>

<script>
import eFormDialog from '@/components/bussetup/merchantcommissionrules/form'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del,DisableOrEnableRule } from '@/api/bussetup/tMerchantCommissionRules'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/bussetup/merchantcommissionrules/header'

import { parseMTGroup,parseCategorys,parseIsValid,parseIndirectCommType,parseDirectCommType,parseCommType,parseRewardType } from '@/views/pt/ib/utils/index'
export default {
  name:'commrule',
  components: { eHeader, eFormDialog },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      modeifyLoading: false,
      dirrule:'',
      currentRow: {},
      isAdd: false,
      dialogShow: false
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseMTGroup,
    parseCategorys,
    parseTime,
    checkPermission,
    parseIsValid,
    parseIndirectCommType,
    parseDirectCommType,
    parseCommType,
    parseRewardType,
    ibLevChange(ibLvl){
      this.query.ibLvl = ibLvl;
    },
    beforeInit() {
      this.url = 'crm/tMerchantCommissionRules'
      const sort = 'id,desc'
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort,
        // 返佣规则页面新增一个固定查询参数 custId=0
        custId: 0,
      }
      const query = this.query
      const ruleName = query.ruleName
      const rewardType = query.rewardType
      let ibLvl = query.ibLvl;
      if(this.$route.query.ibLvl ){
        ibLvl = this.$route.query.ibLvl;
        this.$route.query.ibLvl = null
      }

      const commType = query.commType
      const isvalid = query.isvalid
      const tradeCat = query.tradeCat
      const mtGroup = query.mtGroup
      if (ruleName!=null && ruleName!='') { this.params['ruleName'] = ruleName }
      if (rewardType!=null && rewardType!='') { this.params['rewardType'] = rewardType }
      if (isvalid!=null && isvalid!='') { this.params['isvalid'] = isvalid }
      if (tradeCat!=null && tradeCat!='') { this.params['tradeCat'] = tradeCat }
      if (mtGroup!=null && mtGroup!='') { this.params['mtGroup'] = mtGroup }

      this.params.ibLvl = ibLvl;
      return true
    },
    getTradeCat(tradeCat){
      if(!tradeCat) return '';
      return tradeCat;
    },

    getMtGroup(mtGroup){
      if(!mtGroup) return '';
      return mtGroup;
    },
    parseRules(rule){
      var result='';
      // 需要修改, 股票格式 0.15%
      switch(rule.directCommType){
        case 1:
          result='直接客户:$'+rule.directCommValue+'/手\n'
          this.dirrule='$'+rule.directCommValue
        break;
        case 2:
          result='直接客户:交易量*'+rule.directCommValue+'%\n'
          this.dirrule='%'+rule.directCommValue
        break;
        case 3:
          result='直接客户:'+rule.directCommValue+'pips/手\n'
          this.dirrule='pips$'+rule.directCommValue
        break;
        case 4:
          result='直接客户:'+ (rule.directCommValue * 100) +'%\n'
          this.dirrule='pips$'+rule.directCommValue
          break;
      }
      if(rule.rewardType!=2){
        switch(rule.indirectCommType){
        case 1:
           result+='间接客户:'+ '上级标准-下级标准\n'
        break;
        case 2:
          result+='间接客户:$'+rule.indirectCommValue+'/手'
        break;
        case 3:
         result+='间接客户:返佣总额*'+rule.indirectCommValue+'%\n'
        break;
        case 4:
        result+='间接客户:'+rule.indirectCommValue+'/pips/手\n'
        break;
        }
      }
      return result
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
      })
    },
    subStatus(rule) {
      this.modeifyLoading = true;
      let apiData = {
        id: rule.id,
      };
      if(rule.isvalid==1){
        apiData.isvalid=2
      }else {
        apiData.isvalid=1
      }
      DisableOrEnableRule(apiData).then(res => {
        this.modeifyLoading = false
        this.$refs[rule.ruleName].doClose()
        this.init()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.modeifyLoading = false
        this.$refs[rule.ruleName].doClose()
      })
    },
    clearHandle(){
      this.query = {};
      this.toQuery();
    },
    getSomeOne(row){
      return (!row.custId && checkPermission(['ADMIN','COMMISSIONRULES_ALL','COMMISSIONRULES_DISABLE'])) || (!row.custId && checkPermission(['ADMIN','COMMISSIONRULES_ALL','COMMISSIONRULES_DELETE']));
    },
    success(){
      this.dialogShow = false;
      this.init();
    },
    editHandle(row){
      this.currentRow = row;
      this.isAdd = false;
      this.dialogShow = true;
    },
    addHandldle(){
      this.currentRow = {};
      this.isAdd = true;
      this.$refs.formdialog.resetForm();
      this.dialogShow = true;

    },

    dialogCloseHandle(){
      this.dialogShow = false;
    }
  }
}
</script>

<style scoped>

</style>
