import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'crm/tMerchantCommissionRules',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'crm/tMerchantCommissionRules/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'crm/tMerchantCommissionRules',
    method: 'put',
    data
  })
}

/**
 * 参数：id : 规则id
 isvalid: 1:启用  2:禁用
 * @param data
 * @constructor
 */
export function DisableOrEnableRule(data) {
  return request({
    url: 'crm/tMerchantCommissionRulesIsvalid',
    method: 'put',
    data
  })
}
